import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import { PricingList, PricingListItem } from '../components/pricingList';

const GroomingRates = () => {
  var pageTitle = 'Grooming Rates';

  return (
    <Layout
      slug="grooming"
      title={pageTitle}
      description="Find out more about the services we offer and our rates!"
    >
      <h1>{pageTitle}</h1>

      <h2 className="h1 text-center">Individual Services</h2>
      <span className="block text-center mb-4">
        Please note these prices are an estimate and prices can vary based on
        temperament.
      </span>
      <PricingList listClasses="mb-20">
        <PricingListItem
          title="Nail Trim"
          price="$12-17"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0 ">
            <li>
              Nail Trim or Dremel<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Face Trim"
          price="$10-20"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Face and Eye Brow Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="At Home Nail Care"
          price="$15-25"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              We come to you<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Nail Trim or Dremel</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Anal Gland Expression"
          price="$15"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Add-on to any grooming service
              <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Anal Gland Expression</li>
          </ul>
        </PricingListItem>
      </PricingList>

      <h2 className="h1 text-center">Small Dog Grooming Rates</h2>
      <span className="block text-center mb-4">
        (Shih Tzu, Pomeranian, Chihuahua, Maltese) <br />
        Please note these prices are an estimate and prices can vary based on
        temperament and matting
      </span>
      <PricingList listClasses="mb-20">
        <PricingListItem
          title="Bath &amp; De-shed"
          price="$35-45"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0 ">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              De-shedding treatment <hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Nail Trim</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Face, Feet &amp; Sanitary"
          price="$45-55"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Brush<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Nail Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Face, Feet, Fringe and Sanitary trim</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Full Service Groom"
          price="$50-65"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Brush<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Nail Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Full Body Trim</li>
          </ul>
        </PricingListItem>
      </PricingList>

      <h2 className="h1 text-center">Medium Dog Grooming Rates</h2>
      <span className="block text-center mb-4">
        (Cocker Spaniel, Heeler, Springer Spaniel, American Eskimo)
        <br />
        Please note these prices are an estimate and prices can vary based on
        temperament and matting
      </span>
      <PricingList listClasses="mb-20">
        <PricingListItem
          title="Bath &amp; De-shed"
          price="$50-65"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0 ">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              De-shedding treatment<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Nail Trim</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Face, Feet &amp; Sanitary"
          price="$55-70"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Brush<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Nail Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Face, Feet, Fringe and Sanitary trim</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Full Service Groom"
          price="$55-75"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Brush<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Nail Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Full Body Trim</li>
          </ul>
        </PricingListItem>
      </PricingList>

      <h2 className="h1 text-center">Large Dog Grooming Rates</h2>
      <span className="block text-center mb-4">
        (Lab, Golden Retriever, Standard Poodle) <br />
        Please note these prices are an estimate and prices can vary based on
        temperament and matting
      </span>
      <PricingList listClasses="mb-20">
        <PricingListItem
          title="Bath &amp; De-shed"
          price="$70-120"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0 ">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              De-shedding treatment<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Nail Trim</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Face, Feet &amp; Sanitary"
          price="$80-130"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Brush and deshedding<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Nail Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Face, Feet, Fringe and Sanitary trim</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Full Service Groom"
          price="$80-130"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Brush<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Nail Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Full Body Trim</li>
          </ul>
        </PricingListItem>
      </PricingList>

      <h2 className="h1 text-center">X-Large Dog Grooming Rates</h2>
      <span className="block text-center mb-4">
        (Great Pyranees, Newfoundland Retriever, Leonberger)
        <br />
        Please note these prices are an estimate and prices can vary based on
        temperament and matting
      </span>
      <PricingList listClasses="mb-20">
        <PricingListItem
          title="Bath &amp; De-shed"
          price="$110-200+"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0 ">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              De-shedding treatment<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Nail Trim</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Face, Feet &amp; Sanitary"
          price="$120-200+"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Brush<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Nail Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Face, Feet, Fringe and Sanitary trim</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Full Service Groom"
          price="$120-200+"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Brush<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Nail Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Full Body Trim</li>
          </ul>
        </PricingListItem>
      </PricingList>

      <h2 id="feline-groom-rates" className="h1 text-center">
        Feline Grooming Rates
      </h2>
      <span className="block text-center mb-4">
        Please note these prices are an estimate and prices can vary based on
        temperament and matting
      </span>
      <PricingList listClasses="mb-20">
        <PricingListItem
          title="Bath &amp; De-shed"
          price="$40+"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0 ">
            <li>
              Bath<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              De-shedding treatment<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Nail Trim (Optional)</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Pawdicures"
          price="$12-25"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>Nail Trim or Nail Cap Application</li>
          </ul>
        </PricingListItem>
        <PricingListItem
          title="Full Service Groom"
          price="$50+"
          itemClasses="bg-vip-turquoise"
          headClasses="bg-gray-200 text-center p-4"
          bodyClasses="text-center p-4"
        >
          <ul className="list-none pl-0">
            <li>
              Brush<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>
              Full Body Trim<hr className="w-1/3 mx-auto my-2"></hr>
            </li>
            <li>Nail Trim (Optional)</li>
          </ul>
        </PricingListItem>
      </PricingList>
      <p>
        <b>Note:</b> These prices are just an estimate and there can be
        additional fees due to fleas, ticks, females in heat, matting etc. For a
        more specific quote please <Link to="/contact/">contact us</Link>.
      </p>
      <h2>Cancellation Policy</h2>
      <p>
        Since we operate by appointment we ask that you give us 48hrs notice if
        you have to cancel your appointment for any reason. If you do not cancel
        with 48hrs notice and we are able to fill the appointment there will be
        no cancellation fee applied. If we are unable to fill the appointment
        due to the short notice there will be a $25 fee applied to your next
        appointment with us.
      </p>
    </Layout>
  );
};

export default GroomingRates;
